import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import CompanyPricing from './pages/CompanyPricing';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Register from './pages/auth/Register';
import Login from './pages/auth/Login';
import CompanyLogin from './pages/auth/CompanyLogin';
import CompanyRegister from './pages/auth/CompanyRegister';
import Dashboard from './pages/user/Dashboard';
import UserLayout from './pages/user/UserLayout';
import ChatPage from './pages/user/ChatPage';
import BillingPage from './pages/user/BillingPage';
import ProfilePage from './pages/user/ProfilePage';
import ProtectedRoute from './pages/auth/ProtectedRoute';
import CompanyProtectedRoute from './pages/auth/CompanyProtectedRoute';
import HrLayout from './pages/Hr/HrLayout';
import HrDash from './pages/Hr/HrDash';
import RegisterStaff from './pages/Hr/RegisterStaff';
import HrStaff from './pages/Hr/HrStaff';
import SendEmails from './pages/Hr/SendEmails';
import StaffPreview from './pages/Hr/StaffPreview';
import AdminLayout from './pages/Admin/components/AdminLayout';
import AdminAddPlan from './pages/Admin/AdminAddPlan';
import AdminPlans from './pages/Admin/AdminPlans';
import AdminAddCounsellor from './pages/Admin/AdminAddCounsellor';
import AdminCounsellors from './pages/Admin/AdminCounsellors';
import AdminAddCompany from './pages/Admin/AdminAddCompany';
import AdminCompanies from './pages/Admin/AdminCompanies';
import AdminAddUser from './pages/Admin/AdminAddUser';
import AdminUserSubs from './pages/Admin/AdminUserSubs';
import AdminUsers from './pages/Admin/AdminUsers';
import AdminDash from './pages/Admin/AdminDash';
import AdminLogin from './pages/Admin/AdminLogin';
import AdminProtectedRoute from './components/AdminProtectedRoute';
import VerifyEmail from './pages/user/VerifyEmail';
import RegistrationConfirm from './pages/user/RegistrationConfirm';
import ChosenPlan from './pages/ChosenPlan';
import ForgotPassword from './pages/auth/ForgotPassword';
import PreferencesPage from './pages/user/PreferencesPage';
import AnalyticsPage from './pages/user/AnalyticsPage';
import AdminEvents from './pages/Admin/AdminEvents';
import AboutPage from './pages/AboutPage';
import UsersPage from './pages/UsersPage';
import CorpPage from './pages/CorpPage';
import Maintenance from './pages/Maintenance';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path='/' element={<HomePage />} /> */}
        <Route path='/' element={<Maintenance />} />
        
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
