import React from 'react'
import maint from "../assets/vecteezy_server-maintenance-illustration-concept-on-white-background_11634383-removebg-preview.png"
import logo from "../assets/uzima-logo.png"

const Maintenance = () => {
    return (
        <div className='flex flex-col items-center justify-center w-full h-full'>
            <div className='w-[100px] h-[100px] mb-4'>
                <img src={logo} alt="" className='w-full h-full' />
            </div>
            <div className='flex items-center flex-col'>
                <h1 className='font-bold text-2xl'>We'll be back soon</h1>
                <p className='text-gray-400'>Uzima is undergoing maintenance and will be back shortly.</p>
            </div>
            <div className='w-[400px] h-[400px] object-cover'>
                <img src={maint} alt="" className='w-full h-full object-cover' />
            </div>
        </div>
    )
}

export default Maintenance